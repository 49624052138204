import React from 'react';
import { Link } from 'gatsby';
import cls from 'classnames';
import style from './AnimatedLinkButton.module.scss';

export interface Props {
  to: string;
  color: string;
  className?: string;
}

const AnimatedLinkButton: React.FC<Props> = (props) => {
  return (
    <Link
      to={props.to}
      className={cls(
        style.AnimatedLinkButton,
        style[props.color],
        props.className
      )}
    >
      <span>{props.children}</span>
    </Link>
  );
};

export default AnimatedLinkButton;
