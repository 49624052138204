import React from 'react';
import Layout from '~/components/layout/layout/layout';
import Success from '~/components/pages/successpage/success';
import SEO from '~/components/common/SEO/SEO';

const SuccessPage = () => (
  <Layout>
    <SEO title="Purchase success" />
    <Success />
  </Layout>
);

export default SuccessPage;
