import React, { useEffect } from 'react';
import { useCartContext } from '~/ducks/shopping/shopping-context';
import * as localStorageUtils from '~/utils/local-storage';
import FullPageCentered from '../../layout/full-page-centered/full-page-centered';
import AnimatedLinkButton from '../../input/AnimatedLinkButton/AnimatedLinkButton';

const Success = () => {
  const { clearCart } = useCartContext();

  useEffect(() => {
    localStorageUtils.setCart([]);
    clearCart();
  }, [clearCart]);

  return (
    <FullPageCentered backgroundColor="darkgreen">
      <h2>Thank you!</h2>
      <p>You are a hero! We will handle your order as soon as we can.</p>

      <AnimatedLinkButton color="red" to="/" className="mt-8">
        Go back home
      </AnimatedLinkButton>
    </FullPageCentered>
  );
};

export default Success;
