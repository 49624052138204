import React from 'react';
import cls from 'classnames';
import style from './full-page-centered.module.scss';

export interface Props {
  backgroundColor: string;
  className?: string;
  innerClassName?: string;
}

const FullPageCentered: React.FC<Props> = props => {
  return (
    <div
      className={cls(
        style.FullPageCentered,
        style[props.backgroundColor],
        props.className
      )}
    >
      <div className={cls(style.inner, props.innerClassName)}>
        {props.children}
      </div>
    </div>
  );
};

export default FullPageCentered;
